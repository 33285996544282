@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base page styles */
body {
  background-color: #050505;
  transition: background-color 0.3s ease;
  min-height: 100vh;
  overflow-x: hidden;
}

body.light {
  background-color: #ffffff;
}

/* Leather pattern background */
.leather-pattern {
  background-size: 80px 80px;
  opacity: 0.9;
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 1;
}

/* Main content section */
.main-content {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  padding: 2rem 0;
}

/* Content wrapper */
.content-wrapper {
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

/* Sticky header styles */
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background: transparent;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.sticky::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(5, 5, 5, 0.5);
  z-index: -1;
}

.light .sticky::before {
  background: rgba(255, 255, 255, 0.5);
}

/* Menu overlay */
.menu-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 45;
  display: flex;
  justify-content: flex-end;
}

.menu-overlay.open {
  opacity: 1;
  visibility: visible;
}

.menu-content {
  position: relative;
  width: 300px;
  height: 100%;
  background: rgba(17, 17, 17, 0.95);
  backdrop-filter: blur(12px);
  border-left: 1px solid rgba(255, 223, 0, 0.1);
  padding: 6rem 2rem 2rem;
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.menu-overlay.open .menu-content {
  transform: translateX(0);
}

.light .menu-content {
  background: rgba(255, 255, 255, 0.95);
  border-left: 1px solid rgba(255, 223, 0, 0.2);
}

.menu-content nav {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.menu-content button {
  font-size: 1.125rem;
  letter-spacing: 0.05em;
  padding: 0.5rem 1rem;
  text-align: left;
  transition: all 0.2s ease;
  border-radius: 8px;
  width: 100%;
}

.menu-content button:hover {
  background: rgba(255, 223, 0, 0.1);
}

.light .menu-content button:hover {
  background: rgba(184, 134, 11, 0.1);
}

/* Luxury home container */
.luxury-home-container {
  position: relative;
  height: calc(100vh - 240px);
  max-height: 600px;
  border-radius: 24px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 223, 0, 0.2);
  transition: background-image 0.5s ease;
}

.light .luxury-home-container {
  background-image: url('https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-4.0.3');
}

.dark .luxury-home-container {
  background-image: url('/src/images/dark_background.jpg');
}

/* Glass container styles */
.glassmorphic-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 800px;
  height: auto;
  backdrop-filter: blur(10px);
  border-radius: 24px;
  transition: all 0.3s ease;
}

.light .glassmorphic-overlay {
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 4px rgba(0, 0, 0, 0.05);
}

.dark .glassmorphic-overlay {
  background: rgba(17, 17, 17, 0.95);
  border: 1px solid rgba(255, 223, 0, 0.1);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.4),
    0 2px 4px rgba(255, 223, 0, 0.05);
}

.glass-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  transition: all 0.3s ease;
}

.dark .glass-content {
  color: rgba(255, 255, 255, 0.95);
}

.light .glass-content {
  color: rgba(0, 0, 0, 0.9);
}

/* Button styles */
.glass-button {
  border-radius: 12px;
  padding: 1.5rem;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.dark .glass-button {
  background: rgba(255, 223, 0, 0.1);
  border: 1px solid rgba(255, 223, 0, 0.3);
}

.light .glass-button {
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(255, 223, 0, 0.2);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.05),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.glass-button:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 223, 0, 0.4);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2),
              0 0 20px rgba(255, 223, 0, 0.1);
}

.light .glass-button:hover {
  background: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 223, 0, 0.4);
  box-shadow: 
    0 8px 20px rgba(0, 0, 0, 0.1),
    0 0 20px rgba(255, 223, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.6);
}

/* Contact button */
.contact-button {
  background: linear-gradient(
    135deg,
    rgba(255, 223, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 223, 0, 0.2);
  border-radius: 9999px;
  transition: all 0.2s ease;
}

.contact-button:hover {
  transform: translateY(-2px);
  border-color: rgba(255, 223, 0, 0.4);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

/* Business card styles */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 100;
}

.modal-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.business-card {
  position: relative;
  width: 400px;
  aspect-ratio: 1.75;
  border-radius: 12px;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  transition: all 0.2s ease;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.8);
}

.business-card::before {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: 14px;
  background: linear-gradient(45deg, #FFD700, #FFA500, #FFD700);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.business-card:hover::before {
  opacity: 1;
}

.card-content {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.9);
  color: #B8860B;
}

.card-content::before {
  content: '';
  position: absolute;
  inset: 0;
  background-image: linear-gradient(45deg, transparent 48%, rgba(255,223,0,0.1) 50%, transparent 52%);
  background-size: 10px 10px;
  pointer-events: none;
  opacity: 0.3;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #FFD700;
  transition: transform 0.2s ease;
  z-index: 2;
}

.modal-close:hover {
  transform: scale(1.1);
}

/* Blog Page Styles */
.blog-page {
  min-height: calc(100vh - 4.5rem);
  margin-top: 4.5rem;
  padding: 3rem;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.blog-header {
  margin-bottom: 4rem;
  padding: 4rem;
  background: rgba(17, 17, 17, 0.75);
  border-radius: 32px;
  border: 1px solid rgba(255, 223, 0, 0.15);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.4),
    0 2px 4px rgba(255, 223, 0, 0.05),
    inset 0 0 100px rgba(255, 223, 0, 0.02);
  position: relative;
  overflow: hidden;
}

.blog-header::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 223, 0, 0.03) 50%,
    transparent 100%
  );
  pointer-events: none;
}

.light .blog-header {
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(255, 223, 0, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 4px rgba(255, 223, 0, 0.05),
    inset 0 0 100px rgba(255, 223, 0, 0.05);
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 2.5rem;
  max-width: 1800px;
  margin: 0 auto;
}

.blog-card {
  background: rgba(17, 17, 17, 0.65);
  border-radius: 24px;
  border: 1px solid rgba(255, 223, 0, 0.15);
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  min-height: 520px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.4),
    0 2px 4px rgba(255, 223, 0, 0.05),
    inset 0 0 100px rgba(255, 223, 0, 0.02);
  position: relative;
}

.blog-card::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    45deg,
    transparent 0%,
    rgba(255, 223, 0, 0.03) 50%,
    transparent 100%
  );
  pointer-events: none;
}

.light .blog-card {
  background: rgba(255, 255, 255, 0.65);
  border: 1px solid rgba(255, 223, 0, 0.2);
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.1),
    0 2px 4px rgba(255, 223, 0, 0.05),
    inset 0 0 100px rgba(255, 223, 0, 0.05);
}

.blog-card:hover {
  transform: translateY(-6px) scale(1.02);
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.4),
    0 0 30px rgba(255, 223, 0, 0.1),
    inset 0 0 100px rgba(255, 223, 0, 0.03);
}

.light .blog-card:hover {
  box-shadow: 
    0 20px 40px rgba(0, 0, 0, 0.15),
    0 0 30px rgba(255, 223, 0, 0.1),
    inset 0 0 100px rgba(255, 223, 0, 0.08);
}

.blog-image {
  height: 280px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.blog-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(17, 17, 17, 0.4) 50%,
    rgba(17, 17, 17, 0.95) 100%
  );
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.light .blog-overlay {
  background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.95) 100%
  );
}

.blog-content {
  padding: 2.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}

.blog-content::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    transparent 0%,
    rgba(255, 223, 0, 0.02) 50%,
    transparent 100%
  );
  pointer-events: none;
}

.blog-title {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.95);
  letter-spacing: 0.02em;
}

.light .blog-title {
  color: rgba(0, 0, 0, 0.9);
}

.blog-excerpt {
  font-size: 1.125rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7);
  flex: 1;
}

.light .blog-excerpt {
  color: rgba(0, 0, 0, 0.7);
}

.blog-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: auto;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(255, 223, 0, 0.1);
  color: rgba(255, 223, 0, 0.8);
  font-size: 0.9375rem;
}

.light .blog-meta {
  color: #B8860B;
  border-top: 1px solid rgba(184, 134, 11, 0.2);
}

.meta-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.blog-read-more {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #FFD700;
  font-size: 0.9375rem;
  padding: 1rem 1.5rem;
  background: rgba(255, 223, 0, 0.1);
  border: 1px solid rgba(255, 223, 0, 0.3);
  border-radius: 12px;
  transition: all 0.3s ease;
  width: fit-content;
  margin-top: 1.5rem;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.light .blog-read-more {
  color: #B8860B;
  background: rgba(184, 134, 11, 0.1);
  border: 1px solid rgba(184, 134, 11, 0.3);
}

.blog-read-more:hover {
  background: rgba(255, 223, 0, 0.15);
  border-color: rgba(255, 223, 0, 0.4);
  transform: translateX(8px);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.2),
    0 0 20px rgba(255, 223, 0, 0.1);
}

.light .blog-read-more:hover {
  background: rgba(184, 134, 11, 0.15);
  border-color: rgba(184, 134, 11, 0.4);
  box-shadow: 
    0 4px 12px rgba(0, 0, 0, 0.1),
    0 0 20px rgba(184, 134, 11, 0.1);
}

/* Mobile-optimized content card */
@media (max-width: 768px) {
  .main-content {
    min-height: calc(100vh - 4.5rem);
    margin-top: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .content-card {
    margin: 1rem;
    padding: 1.5rem;
    background: rgba(17, 17, 17, 0.95);
    border-radius: 24px;
    border: 1px solid rgba(255, 223, 0, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 
      0 8px 32px rgba(0, 0, 0, 0.4),
      0 2px 4px rgba(255, 223, 0, 0.05);
  }

  .light .content-card {
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid rgba(255, 223, 0, 0.2);
    box-shadow: 
      0 8px 32px rgba(0, 0, 0, 0.1),
      0 2px 4px rgba(255, 223, 0, 0.05);
  }

  .content-card h3 {
    font-size: 0.875rem;
    letter-spacing: 0.1em;
    margin-bottom: 1rem;
  }

  .content-card h1 {
    font-size: 2rem;
    line-height: 1.1;
    margin-bottom: 0.5rem;
    letter-spacing: 0.02em;
  }

  .content-card p {
    font-size: 1rem;
    line-height: 1.5;
    margin: 1rem 0 1.5rem;
    opacity: 0.9;
  }

  .mobile-cta-button {
    width: 100%;
    padding: 1rem;
    margin: 0.5rem 0;
    border-radius: 12px;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
  }

  .mobile-cta-primary {
    background: #FFD700;
    color: #000000;
    border: none;
  }

  .mobile-cta-secondary {
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    border: 1px solid rgba(255, 223, 0, 0.3);
  }

  .light .mobile-cta-secondary {
    background: rgba(0, 0, 0, 0.05);
    color: #000000;
    border: 1px solid rgba(184, 134, 11, 0.3);
  }

  .mobile-cta-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }

  .mobile-business-card {
    position: fixed;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.625rem 1.25rem;
    background: rgba(17, 17, 17, 0.95);
    border: 1px solid rgba(255, 223, 0, 0.2);
    border-radius: 9999px;
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: #FFD700;
    font-size: 0.8125rem;
    letter-spacing: 0.05em;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    z-index: 40;
  }

  .light .mobile-business-card {
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid rgba(184, 134, 11, 0.2);
    color: #B8860B;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

/* Responsive adjustments */
@media (max-width: 1440px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  }
}

@media (max-width: 1024px) {
  .blog-grid {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}

@media (max-width: 768px) {
  .blog-page {
    padding: 2rem 1rem;
  }

  .blog-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .blog-header {
    padding: 2rem;
    margin-bottom: 2rem;
  }

  .blog-card {
    min-height: auto;
  }

  .blog-image {
    height: 250px;
  }

  .blog-content {
    padding: 2rem;
    gap: 1.25rem;
  }

  .blog-title {
    font-size: 1.5rem;
  }

  .blog-excerpt {
    font-size: 1rem;
  }

  .blog-meta {
    gap: 1.25rem;
    padding-top: 1.25rem;
  }

  .blog-read-more {
    padding: 0.875rem 1.25rem;
    margin-top: 1.25rem;
  }
}

/* Utility classes */
.backdrop-blur {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
}

.gold-text {
  color: #FFD700;
  text-shadow: 0 0 10px rgba(255,223,0,0.3);
}

/* Focus states */
button:focus,
a:focus {
  outline: 2px solid rgba(255, 223, 0, 0.5);
  outline-offset: 2px;
}

/* Performance optimizations */
* {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
